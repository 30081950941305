import React from "react"

import Container from "react-bootstrap/Container"
import { Row, Col } from "react-bootstrap"

import Carousel from "react-bootstrap/Carousel"
import ResponsiveEmbed from "react-bootstrap/ResponsiveEmbed"
import SEO from "../../components/seo"
import Image from "../../components/image"
import Layout from "../../components/layout"
import Paginator from "../../components/paginator"

const SegmentingAndLabelingPointClouds = ({ location }) => {
  return (
    <Layout>
      <SEO title="Luk Vermeulen" />
      <Container>
        <Carousel className="mt-3">
          <Carousel.Item>
            <Image
              filename="Bachelorthesis/segmented-pointcloud.jpg"
              alt="segmented pointcloud"
            />
          </Carousel.Item>
          <Carousel.Item>
            <ResponsiveEmbed aspectRatio="16by9">
              <iframe
                src="https://www.youtube.com/embed/5NL3bwei8og"
                title="Segmenting and labeling point clouds in vr"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                frameBorder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
              />
            </ResponsiveEmbed>
          </Carousel.Item>
        </Carousel>

        <div className="row mt-3">
          <div className="col-md-4">
            <dl>
              <dt>Title</dt>
              <dd>
                Segmenting and labeling point clouds in VR{" "}
                <small>Bachelorthesis</small>
              </dd>
              <dt>Type</dt>
              <dd>Research</dd>
              <dt>Year</dt>
              <dd>2019</dd>
            </dl>
          </div>
          <div className="col-md-8">
            <p>
              With the increasing importance of design and construction in
              existing contexts, methods for "as-built" documentation of
              buildings are used more and more. Especially since the uprise of
              BIM, the demand for 3D data increases. Such 3D data, often in the
              form of point clouds, could come from laserscanning or
              photogrammetry. To transform these survey data into usable BIM's
              (Scan to BIM), lots of manual labour is still required. Current
              programs and methods, especially for the AEC industry, are still
              at an early stage of development and often very specialized or
              hard to use. Consequently the motivation arose to look for
              possibilities to make manual processing of point clouds more
              intuitive and accessible to a larger audience. Thus, my
              bachelorthesis deals with the questions of how make the processing
              of point clouds from the AEC industry more intuitive, which steps
              are most important and is virtual reality an answer to these
              questions? The result is a software prototype, which enables the
              cleaning, segmenting and labeling of point clouds in virtual
              reality.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4 mb-3">
            <Image
              filename="Bachelorthesis/brush-select.jpg"
              alt="segmenting a point cloud with a brush"
            />
          </div>
          <div className="col-md-4 mb-3">
            <Image
              filename="Bachelorthesis/box-select.jpg"
              alt="segmenting a point cloud with a box"
            />
          </div>
          <div className="col-md-4 mb-3">
            <Image
              filename="Bachelorthesis/plane-select.jpg"
              alt="segmenting a point cloud with a plane"
            />
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-8">
            <p>
              For the prototype I focused on three selection algorithms, as seen
              in the images above. The brush select allows the selection of
              smaller point groups and is very precise. The radius of the brush
              and the distance to oneself are controllable. The box select is
              more focused on larger objects (e.g. construction machines). Since
              most buildings nowadays are composed of planar elements, the plane
              select is useful for selecting complete walls or elements within a
              wall.
              <br />
              Comparing these methods of point selection in VR to existing
              methods on 2D screens, resulted in the VR methods being slightly
              faster and more intuitive (for untrained individuals in both
              softwares). Also the astonishing quality of dense point clouds led
              to the idea of using survey data for virtual site visits. Merging
              this with selection and annotating tools to exchange and create
              semantic data (e.g. Issues, damage maps) to integrate this into
              BIM processes could be an interesting topic for further research.
            </p>
          </div>
          <div className="col-md-4">
            <dl>
              <dt>Schependomlaan Dataset</dt>
              <dd>
                <a
                  className="text-dark"
                  href="https://github.com/buildingSMART/Sample-Test-Files/tree/master/IFC%202x3/Schependomlaan"
                >
                  github.com/buildingSMART
                </a>
              </dd>
              <dt>Semantic3d Dataset</dt>
              <dd>
                <a className="text-dark" href="http://semantic3d.net">
                  www.semantic3d.net
                </a>
              </dd>
              <dt>Unreal Engine 4.22</dt>
              <dd>
                <a
                  className="text-dark"
                  href="https://www.unrealengine.com/en-US"
                >
                  www.unrealengine.com/en-US/
                </a>
              </dd>
              <dt>PointCloudPlugin v.0.5.5</dt>
              <dd>
                <a className="text-dark" href="https://pointcloudplugin.com">
                  www.pointcloudplugin.com
                </a>
              </dd>
            </dl>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <Image
              filename="Bachelorthesis/scan-to-bim.jpg"
              alt="scan to bim process visualized"
            />
          </div>
          <div className="col-6">
            <Image
              filename="Bachelorthesis/site-visit-in-vr.jpg"
              alt="dense point cloud"
            />
          </div>
        </div>

        <Paginator currentPathname={location.pathname} />
      </Container>
    </Layout>
  )
}

export default SegmentingAndLabelingPointClouds
